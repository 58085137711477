body {
    margin: 0;
    padding: 0;
}

.Crop {
    width: 80vw;
    margin-left: 20vw;
    margin-top: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.Crop .reactEasyCrop_Container {
    touch-action: auto;
}

.Crop .removeTouchAction {
    touch-action: none;
}

.crop-container {
    background-color: #e1e2e6;
    position: relative;
    height: 400px;
}

.controls {
    width: 50%;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
}

.slider {
    padding: 22px 0px;
}

.zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #3f51b5;
    width: 100%;
    box-sizing: content-box;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.zoom-hint,
.touch-hint {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    pointer-events: none;
    text-align: center;
}
.viewer {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.output {
    position: relative;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
}
#selectFile[type=text] {padding:5px; border:2px solid #ccc;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
#selectFile[type=text]:focus {border-color:#333; }

#selectFile[type=submit] {padding:5px 15px; background:#ccc; border:0 none;
    cursor:pointer;
    -webkit-border-radius: 5px;
    border-radius: 5px; }

.validate {
    text-align: center;
    margin-top: 30px !important;
}
