.phoneIt:hover:not(.makeStyles-disabled-425):before, .phoneIt:before {
    border-color: #D2D2D2!important;
    border-width: 1px !important;
}
 .phoneIt:before {
     left: 0;
     right: 0;
     bottom: 0;
     content: "\00a0";
     position: absolute;
     transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     border-bottom: 1px solid rgba(0, 0, 0, 0.42);
     pointer-events: none;
 }

.PhoneInputInput {
    border: none !important;
}
