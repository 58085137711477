.hm_hyperModalContentWrapper {
    overflow: hidden !important;
    background-color: #dad5d5 !important;
    width: 40% !important;
    height: 20% !important;
}

.hm_visible {
    z-index: 6 !important;
    overflow: hidden;
    background-color: rgba(88, 103, 103, 0.54);
}
