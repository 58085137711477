/**
 The Card stuff
 Graphisme personnalisé des cards
 */

.tester {
    max-width:300px;
    min-width: 290px;
    min-height: 465px;
    overflow:hidden;
    height: 70%;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    /*margin-top: 15px;*/
    padding-top: 30px;
}

.sp-align {
    text-align: center;
    vertical-align: middle;
}

/* Surcharge du Bootstrap Card*/
.card {
    border: none !important;
    background-color: rgb(73, 84, 91);
}

.card-text {
    font-size: 0.8rem;
}

.sp-card-body {
    padding: 0 !important;
}

.overlay-title {
    padding: 5px;
    color: rgb(189, 190, 190);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-family: Tahoma, sans-serif;
    font-size: 1rem !important;
    background: rgba(22, 29, 29, 0.88);
}

.overlay-company-name {
    padding: 2px;
    color: rgb(189, 190, 190);
    font-family: Tahoma, sans-serif;
    font-size: 0.8rem !important;
    margin-bottom: 2px;
}

.sp-card-img {
    object-fit: cover;
}

.card-img-overlay {
    bottom: auto;
}

.card-landing-draft {
    border: 4px solid #db6334 !important;
}

.card-landing {
    background-color: #282c34 !important;
    margin: 15px 0;
    min-height: 500px;
}

.card-align-verticaly {
    display: table-cell !important;
    vertical-align: middle;
}

.inner {
    overflow: hidden;
    transition: max-height 0.2s ease;
}

.expanded {
    text-align: justify;
    background: rgba(53, 66, 72, 0.8);
    padding: 10px;
    color: #ffffff;
    transition: all 0.5s ease;
}
.thumbnailX {
    max-height: 100%;
    max-width: 100%;
}
/* footerX style non collapse des footers de cards sur la page principales*/
.footerX {
    color: #dedcdc;
    background-color: rgba(53, 66, 72, 0.9) !important;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}


/**/
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #9b461e;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 17px 0;
    background-color: #db6334;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 15px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
}

/* top right*/
.ribbon-top-right {
    top: -10px;
    right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}
.ribbon-top-right::before {
    top: 0;
    left: 0;
}
.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}
.ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
}

/*            MEDIA QUERIES                 */
@media (max-width: 480px) {
    .card-landing {
        height: 100% !important;
    }
    .tester {
        /*max-width:300px;*/
        overflow:hidden;
        /*height: 90%;*/
        vertical-align: middle;
        justify-content: center;
        display: flex;
        margin-top: 15px;
        padding-top: 30px;
    }
}
